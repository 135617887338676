body {
	font-family: 'Montserrat', serif;
}

img.bg {
	/* Set rules to fill background */
	min-width: 1024px;

	/* Set up proportionate scaling */
	width: 100%;
	height: auto;

	/* Set up positioning */
	position: fixed;
	bottom: 0;
	left: 0;

	z-index: -100;
}

@media screen and (max-width: 1024px) { /* Specific to this particular image */
	img.bg {
		left: 50%;
		margin-left: -512px;   /* 50% */
	}
}

.navbar-brand {
	height: 70px;
}

.navbar-brand>img {
	max-height: 100%;
	height: 100%;
	width: auto;
	margin: 0 auto;


	/* probably not needed anymore, but doesn't hurt */
	-o-object-fit: contain;
	object-fit: contain;
}

.navbar-offset {
	height: 75px;
}

.grid-sizer,
.grid-item { width: 33%; }

@media screen and (max-width: 1024px) {
	.grid-item { width: 50%; }
}

.blueimp-gallery > .slides > .slide > .text-content {
    overflow: auto;
    margin: 60px auto;
    padding: 0 60px;
    max-width: 920px;
    color: #f8f8f8;
}

.ajax-gallery-view {}
.ajax-gallery-view .separator {
	text-align: right;
	position: relative;
}

.ajax-gallery-view .separator hr {
	z-index: 1;
	width: 100%;
}

.ajax-gallery-view .separator p {
	position: absolute;
	right: 0px;
	top: 2px;
	z-index: 10;
}

.ajax-gallery-view .more {
	margin-top: 20px;
}

#view-solution {}

#view-solution .separator {
	text-align: right;
	position: relative;
}

#view-solution .separator hr {
	z-index: 1;
	width: 100%;
	border-top-color: #777;
}

#view-solution .separator p {
	position: absolute;
	right: 0px;
	top: -24px;
	z-index: 10;
}

.carousel-control.left {
	background-image: none;
}

.carousel-control.right {
	background-image: none;
}

.carousel-control img {
	position: absolute;
	top: 50%;
	z-index: 4;
	display: inline-block;
	margin-top: -45px;
	margin-left: -45px;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
	position: relative;
	min-height: 100%;
}
body {
	/* Margin bottom by footer height */
	margin-bottom: 100px;
}
#infinite-scrolling-loading {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: 100px;
	background-color: transparent;
	border-top: 1px dashed #3f3f3f;
}

/* Steam button
-------------------------------------------------- */
 .btn_addtocart {
	float: left;
	margin-left: 3px;

	position: relative;
	height: 34px;
}

.btn_addtocart .btn_addtocart_left, .btn_addtocart .btn_addtocart_right {
	position: absolute;
	width: 2px;
	height: 34px;
	top: 0px;
	background-image: url('http://store.akamai.steamstatic.com/public/images/v5/btn_addtocart_corners.gif');
}

.btn_addtocart .btn_addtocart_content {
	display: inline-block;
	height: 34px;
	font-size: 11px;
	color: #ffffff;
	text-align: center;
	padding: 0px 11px;
	line-height: 34px;
	background-image: url('http://store.akamai.steamstatic.com/public/images/v5/btn_addtocart_repeat.gif');
	background-repeat: repeat-x;
	text-transform: uppercase;
	cursor: pointer;
}